import React from 'react'

import { Box, Heading, Text } from '@chakra-ui/core'

export default () => {
  return (
    <Box>
      <Heading pt={10}>
        รู้ก่อนเขียนก่อน! 3 เทคนิค เขียน SOP อย่างไร ให้ทรงพลัง
      </Heading>
      <Text py={4}>
        เมื่อพูดถึงการศึกษาต่อต่างประเทศ
        นอกจากกฎเกณฑ์คะแนนสอบที่เราต้องใช้ยื่นในการสมัครแล้ว
        อีกหนึ่งสิ่งที่สำคัญ
        และอาจเป็นตัวชี้ชะตาการได้เข้าเรียนต่อมหาวิทยาลัยในฝันนั้น
        ก็คงหนีไม่พ้นการเขียน Statement of Purpose (SOP)
        หรือจดหมายที่เขียนแนะนำตัวเอง
        และบอกเล่าถึงความตั้งใจในการได้เข้าศึกษาในมหาวิทยาลัยนั้น ๆ
        เพื่อให้ทางมหาวิทยาลัยได้ใช้ประกอบในการตัดสินใจเลือกผู้สมัครนั่นเอง
      </Text>
      <Text py={4}>แล้ว Statement of Purpose ควรจะเขียนอย่างไรดีล่ะ?</Text>
      <Text py={4}>
        ก่อนอื่นต้องบอกว่า Statement of Purpose
        นั้นไม่มีกฎเกณฑ์ในการเขียนที่ตายตัว ไม่มีการเขียนแบบไหนที่ถูกหรือผิด
        มีเพียงแต่การเขียนที่ ‘น่าจดจำ’ และ ‘อ่านยังไง้ก็จำไม่ได้’ เท่านั้น
        ซึ่งแน่นอนว่า SOP ในแบบหลังมักจะถูกลืมเลือน
        และพรากความฝันในการได้เข้าศึกษาจากเราไป
      </Text>
      <Text py={4}>
        คงจะไม่ใช่เรื่องดี
        หากการเตรียมความพร้อมและความตั้งใจทั้งหมดที่ทุ่มให้กับการสมัครครั้งนี้ต้องพังทลายลง
        เพียงเพราะศิลปะการเขียน ที่ยังไม่น่าดึงดูดพอ
      </Text>
      <Text py={4}>
        ฉะนั้นในวันนี้ ‘บอกทุน’ จึงอยาก ‘บอกต่อ’ 3 เทคนิคดี ๆ ที่จะทำให้การเขียน
        SOP มีพลังมากกว่าการเป็นแค่จดหมาย
      </Text>
    </Box>
  )
}
